import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { css, keyframes } from "@emotion/react"

import Seo from "../components/SEO"
import darkWaveImg from "../../static/wavy_dark.svg"

import Footer from "../components/footer"

// const MedalloSubCms = ({
//   medallo,
//   data,
//   location,
//   locale,
//   alternateLanguages,
// }) => {

// }

const MedalloSubCms = ({
  data,
  pageContext: { locale, alternateLanguages },
  location,
}) => {
  const medallo = data.prismicMedallopage
  const ImageCms = getImage(medallo.data.image)
  return (
    <>
      <Seo
        title={medallo.data.features[3].feature_title.text}
        desc={medallo.data.features[3].feature_paragraph.text}
        pathname={location.pathname}
        locale={locale}
        alternateLanguages={alternateLanguages}
      />
      <div
        className={`section  flex flex-col gradient-dark 
         relative h-80 md:min-h-600 0 pt-24   md:pt-52`}
      >
        <div
          className={`text-3xl max-w-5xl font-sans font-bold m-auto px-10 my-3 relative z-10 text-center text-white  
          md:text-5xl md:mt-10 md:mb-2`}
          dangerouslySetInnerHTML={{
            __html: medallo.data.features[3].feature_title.text,
          }}
          css={strongCss}
        />

        <div
          className="text-2xl font-sans font-bold text-white text-center m-auto z-10 mb-10 py-0 px-5 my-0 md:text-4xl"
          dangerouslySetInnerHTML={{
            __html: medallo.data.features[3].feature_paragraph.text,
          }}
          css={strongCss}
        />
        <img
          className="absolute -bottom-4 w-full z-0"
          src={darkWaveImg}
          alt="wave"
        />
      </div>
      <section className=" relative z-10 mx-auto mb-20 px-5   max-w-3xl flex flex-col md:flex md:items-center md:w-3/5 md:pb-36   ">
        <GatsbyImage image={ImageCms} className="mx-10 md:mx-0 mb-20" />
        <div
          className="text-white  text-xl font-sans"
          dangerouslySetInnerHTML={{
            __html: medallo.data.cms_subpage.html,
          }}
          css={CssTextSubpage}
        />
      </section>
      <Footer posts={data.allPrismicBlogpost} />
    </>
  )
}

export default MedalloSubCms

MedalloSubCms.propTypes = {
  data: PropTypes.shape({
    prismicMedallopage: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

const strongCss = css`
  strong {
    background: linear-gradient(
      90deg,
      #f4c82b 3.44%,
      #ecb93d 47.7%,
      #ff6ad5 100%
    );
    background-clip: text;
    text-fill-color: transparent;
  }
`

const CssTextSubpage = css`
  p {
    padding: 10px 0;
  }
`

export const pageQuery = graphql`
  query MedalloSubCmsQuery($locale: String!) {
    prismicMedallopage(lang: { eq: $locale }) {
      alternate_languages {
        uid
        lang
      }
      uid
      data {
        seo_title {
          text
        }
        seo_description {
          text
        }
        title {
          html
          text
        }
        subtitle {
          html
          text
        }
        features {
          feature_title {
            html
            text
          }
          feature_paragraph {
            html
            text
          }
          svg_id_1
          svg_id_2
          svg_id_3
        }

        cms_subpage {
          text
          html
        }
        image {
          gatsbyImageData(width: 600)
        }
      }
    }

    allPrismicBlogpost(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          uid
          lang
          data {
            title {
              text
              html
            }
            body {
              ... on PrismicBlogpostDataBodyContent {
                id
                slice_type
                slice_label
                primary {
                  richtext {
                    text
                    html
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
